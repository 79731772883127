import React from "react";
import { Link, graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";

import IconCall from "../images/icons/theme/communication/call-1.svg";
import IconMail from "../images/icons/theme/communication/send.svg";

import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import IconCheck from "../images/icons/interface/check.svg";

const BizdevrepPage = ({ data, location: { pathname } }) => (
  <Layout headerBgClass="bg-primary-alt" hideFooter footerBgClass="bg-white" meta={{ seo: data.datoCmsPage.seo, path: pathname }}>
    <div className="contact-us-page">
      <div className="contact-us-page__bg bg-primary-alt">
        {/*<div className="divider">
          <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
        </div>*/}
      </div>

      <section className="bg-primary-alt">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h1 className="mb-5">Business Development Representative<br/>Full-Time - 100% Remote</h1>
              <div className="lead">
                <p>We are looking for a talented and competitive Business Development Representative, who will play a fundamental
                  role in achieving our ambitious customer acquisition and revenue growth objectives.
                </p>
              </div>

              {/* <Link
                to="https://adamfard.freshteam.com/jobs/cXzi9XYMxhPs/business-development-manager-american-timezone-remote#applicant-form"
                className="btn btn-lg btn-primary lead-cta"
                target="_blank"
              >
                Apply Now
              </Link> */}

              <h2 className="mt-6 mb-3"><strong>Responsibilities:</strong></h2>
              <ul className="lead mb-5">
                <li className="mb-2">Create business development and sales strategies;</li>
                <li className="mb-2">Target new leads using Sales Nav, Crunchbase, etc.</li>
                <li className="mb-2">Research accounts, identify key players and generate interest;</li>
                <li className="mb-2">Source new sales opportunities through inbound lead follow-up and outbound calls and emails;</li>
                <li className="mb-2">Understand customer needs and requirements;</li>
                <li className="mb-2">Close sales and achieve quotas;</li>
                <li className="mb-2">Perform effective online presentation (UX & Design services) to prospects;</li>
                <li className="mb-2">Manage and grow existing accounts.</li>
              </ul>


              <h2 className="mt-6 mb-3"><strong>Requirements:</strong></h2>
              <ul className="lead mb-5">
                <li className="mb-2">Excellent verbal and written communications skills (English C1);</li>
                <li className="mb-2">Proven inside sales experience;</li>
                <li className="mb-2">Track record of over-achieving quota;</li>
                <li className="mb-2">Strong phone/call presence;</li>
                <li className="mb-2">Strong listening and presentation skills;</li>
                <li className="mb-2"><strong>Experience with (selling) UX / UI Design services is a plus.</strong></li>
              </ul>

              <h2 className="mt-6 mb-3">
                <strong>Benefits:</strong>
              </h2>
              <ul className="lead mb-5">
                <li className="mb-2">Competitive Salary + Commission;</li>
                <li className="mb-2">Working remotely (forever);</li>
                <li className="mb-2">No micromanagement;</li>
                <li className="mb-2">Direct Collaboration with the Marketing and Design teams;</li>
                <li className="mb-2">Managing own campaigns and freedom to experiment;</li>
                <li className="mb-2">Talent Development program to grow professionally &ndash; You will not be stuck in your position or salary.</li>
              </ul>

              {/* <Link
                to="https://adamfard.freshteam.com/jobs/cXzi9XYMxhPs/business-development-manager-american-timezone-remote#applicant-form"
                className="btn btn-lg btn-primary lead-cta"
                target="_blank"
              >
                Apply Now
              </Link> */}


            </div>

       

          </div>
        </div>
      </section>
    </div>

  </Layout>
);

export default BizdevrepPage;

export const query = graphql`
  query bizdevrepPage {
    datoCmsPage(slug: { eq: "business-development-sales-rep" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }
  }
`;
